import React, { useState } from 'react';
import { LinkUIBlueBold, LinkUIUncolored } from './common_links';
import { GenericModal } from './generic_modal';
import { txb, txbb, txrb } from './text_style_components';
import { MockInterviewTopmateHomePage } from '../questions/mock_interview';

/**
 * Single line which shows the message 
 * "Book a mock LLD interview & get CodeZym Premium for free"
 */
const CodeZymPremiumWithMock=()=>{
    return (
      <span class="text-sm text-gray-600 mx-6 md:mx-0
      text-center md:text-left ">
        Book a Mock LLD interview & get Free access to CodeZym Premium <GenericModal title={"details"}
      children={CodeZymPremiumWithMockExpanded()} />  </span>
    )
  }
  
  /**
   * This UI shows details on how will you get codezym premium 
   * when you buy mock interview plan through topmate
   */
  const CodeZymPremiumWithMockExpanded=()=>{
    return (
      <div class="flex flex-col text-gray-900 items-center justify-center p-8">
        <br />
        <div className='text-xl font-semibold text-blue-600 text-center'>
            Free CodeZym Premium access with Mock Interview Booking
            <br />  <br />

        </div>
        <div>
            When you book Low Level Design Mock Interview on 
            <LinkUIBlueBold 
            text="TopMate" 
            url="https://topmate.io/prashant_priyadarshi/"  />
            then you also get free access to CodeZym premium for 1 year along with it.
         </div>
         <div>
         <br />
            After the interview, create an account on CodeZym.com, if not already created.
            <br /><br />
            Then share your CodeZym account email id with us on {txbb("contact@codezym.com")} and we will add premium access to your account.
            </div> 
            
            <div>
                <br />
               {txrb("Important: ")} Premium access will be added {txb("after")}
               mock interview session is complete and {txrb('not')}  when interview is booked.<br />
                This is done to avoid confusion arising due to cancellations and reschedules.
            </div>
            <br />
            <div className='text-xl  text-orange-600
             bg-orange-100 hover:bg-orange-300 
             hover:text-gray-800 p-4 rounded-full'>
                <LinkUIUncolored text="Book Mock Interview"
                url="https://topmate.io/prashant_priyadarshi/665067"
                 />
            </div>
      </div>
    )
  }
  
  export {CodeZymPremiumWithMock};