import React, { useState, useEffect, Children } from 'react';
import { errorMsg, homeUrl, rearrangeArray, success } from '../utils/common_utils';
import IndefiniteProgressBar from "../ui_components/indefinite_progress_bar";
import {NavbarTop} from '../ui_components/navbar_top';
import { YoutubeVideoDisplay } from '../ui_components/youtube_video_display';
import { LinkUI } from '../ui_components/common_links';
import { txb, txbb, txhb, txhbb } from '../ui_components/text_style_components';
import { BRR, ExpandCloseTab, SPACE, SPACE2 } from '../ui_components/common_ui_components';
import { isPremiumUser } from '../login/auth_utils';
import { VisitPremiumSection } from '../premium/premium_discounts';

function ParkingLotQ01UI(){
  return(
  <div className="flex flex-col  items-center justify-center">
  <div className='flex flex-row-responsive items-center justify-center'>
  <div className='md:mx-12'>{txbb("Practice Problem")}  <br />
  {txb("Design a Parking Lot")} <br />
  <LinkUI url="https://codezym.com/question/1" />  <br />  <br />
  Parking Lot design is sort of the {txbb("Hello World")} of <BRR />
   low level design questions. 
  Most people start their  <BRR /> LLD interview preparation with this question. <br /> <br />
  {txbb("Python")} solution video on the right takes you through  <BRR /> the core features  like  park, remove vehicle, search vehicle  <BRR /> and also teaches you how to clearly explain your design   <BRR /> to the interviewer step by step in a 45-60 minute LLD interview.   
  <br /> <br />
  For {txbb("Java")}, we have to implement our solution for a <BRR />  {txbb("multi-threaded")} environment using locking <BRR />  and thread safe data structures. <br />
    Video on the right show a simple solution using {txbb('synchronized')} <BRR />
    keyword 
 which is the   popular locking mechanism in Java. <br /> <br />
 However, using {txbb('synchronized')} or any other lock is simple  <BRR /> 
 but not efficient as it locks out other threads  <BRR /> from doing write operations concurrently. <BRR /> 
   <br /> <br />
  </div>
  <div className="flex flex-col items-center justify-center">
  <div><YoutubeVideoDisplay videoId='7FPnJppdIZM' /></div>
  <br /> 
  <div><YoutubeVideoDisplay videoId='817XIgbH2yk' /></div>
  </div>
  </div>  {/* practice problem row ends */}

  {/* Efficient solution row starts */}  
  <div className='flex flex-row-reverse-responsive items-center justify-center'>
  <div><YoutubeVideoDisplay videoId='JfMciz7lC3M' /></div>

  <div className='mx-12'>
  
 Video on the left shows a more efficient solution 
 using a thread safe list <BRR /> {txbb(' ConcurrentLinkedDeque')} 
 to store free spots on each floor. <br />
 It ensures that on each floor rather than using brute force <BRR />
 to find a free spot, we can add/remove parking spots in {txbb('O(1)')}
   <br /> <br />
  </div>
  
  </div> 

  </div>
    );
}

function Q04InventoryManagementUI(){
return(
<div className="flex flex-col items-center justify-center">

<div className='flex flex-row-responsive items-center justify-center'>
<div className="flex flex-col ">  
{txb("4. Design an order and inventory management system")}
 
{LinkUI({url:"https://codezym.com/question/4"})}  <br />
An inventory management system of a   <BRR />  simple e-commerce platform,   <BRR /> needs to have the capability of   <BRR /> 
handling sellers, products and orders.<br />

Inventory is number of items of a    <BRR /> 
particular product in a seller's warehouse.

</div>
&nbsp;&nbsp;&nbsp;
<div>
<BRR /><BRR />
<YoutubeVideoDisplay videoId='VtBL_NNa7hs' />
</div>
</div>



</div>
);
}

function Q03CustomerIssueResolutionUI(){
  return(
  <div className="flex flex-col items-center justify-center">
  
  <div className='flex flex-row-reverse-responsive items-center justify-center'>
  <div>
  <BRR /><BRR />
  <YoutubeVideoDisplay videoId='03TaNq9_dXs' />
  </div>

  <SPACE2 /><SPACE />

  <div className="flex flex-col ">  
  {txb("3. Design a customer issue resolution system")}
   
  {LinkUI({url:"https://codezym.com/question/3"})}   <BRR /> 
  Customer support is nothing but a group of agents  <BRR /> 
  with skills for solving a set of issues.<br /><br />

Customer Issues can be classified into multiple types  <BRR /> 
like order delayed, Payment Related etc. <BRR /> 
A customer issue resolution system is used to  <BRR /> 
assign customer's issues to agents. <BRR /> 
Watch below YouTube video for a detailed explanation. <BRR /> 
  
  </div>
  </div> 
  </div>
  );
  }

  
function Q06HitCounterUI(){
  return(
  <div className="flex flex-col items-center justify-center">
  
  <div className='flex flex-row-reverse-responsive items-center justify-center'>
  <div>
    <BRR /><BRR />
  <YoutubeVideoDisplay videoId='jU7I2-jWJ8k' />
  </div>

  &nbsp;&nbsp;&nbsp;

  <div className="flex flex-col "> 
    <div>
  {txb("6. Design a Hit Counter/Webpage Visits Counter")}
   <br />
  {LinkUI({url:"https://codezym.com/question/6"})}  <br /><br />
  This is an easy question, yet it gives you a taste of  
  <BRR /> basic data structures to use in a multithreaded environment. 
    <BRR />  This makes it an ideal question to put you 
    in  <BRR />  {txbb('preparation mode')} for your low-level design interviews. <br /> <br />
  </div>
  </div>
  </div> 
  </div>
  );
  }

 function Q07ParkingLotStrategyPatternUI(){
    return(
<div className='flex flex-col items-center justify-center'>
  <div className='flex flex-row-responsive'>
  <div className='md:mx-8'>
  {txb("Design a Parking Lot using Strategy Pattern")}  <br />  
  <LinkUI url="https://codezym.com/question/7" />  <br />   <br />  
  We revisit the Parking Lot design again. <BRR /> However, in this question we have to implement <BRR /> 
   two different parking strategies. 
   <BRR /> So you should use strategy pattern here. <br /> <br />
    {txbb("Python")} solution video takes you  <BRR /> 
     through the core features like   <BRR />   park, remove vehicle, search vehicle and it also  <BRR />  
     teaches you  how to clearly explain your design  <BRR /> 
     to interviewer step by step  <BRR />  in a 45-60 minute LLD interview.   
  <br /> <br />

     {txbb('Python')} video has 
     slightly better explanation   <BRR /> 
     than the {txbb('Java')} video below it.

   <br /> <br />
   </div>
  <div className='flex flex-col items-center justify-center'>
    <YoutubeVideoDisplay videoId='ZIK44dj56fk' />
    <br /><br />
    <YoutubeVideoDisplay videoId='fi_IWW1Ay0o' />
    </div>
  </div> 

      </div>

    );
  }

function SampleFunctionUI(){
    return(
    <div className="flex flex-col items-center justify-center">
    
    <div className='flex flex-row-responsive items-center justify-center'>
    
    </div>
    
    </div>
    );
}

export {
  ParkingLotQ01UI, 
  Q04InventoryManagementUI, 
  Q03CustomerIssueResolutionUI, 
  Q07ParkingLotStrategyPatternUI,
  Q06HitCounterUI
}