import React, { useState } from 'react';

/** e.g. title='Day 1', key ='roadmap1day1',
 *  childern= JSX component
 *  storageKey is used to store components open/close state
 *  in localStorage, it is a must and should be unique
*/
const ExpandCloseTab = ({ title, storageKey, children }) => {
  console.log(storageKey+" "+localStorage.getItem(storageKey))  
  const [isOpen, setIsOpen] = useState(
    (localStorage.getItem(storageKey)||"open")=="open");
  const handleClick = () => {
    let nw=!isOpen
    setIsOpen(nw);
    if(nw)localStorage.setItem(storageKey, "open")
    else localStorage.setItem(storageKey, "close")
  }
  return (
    <div className="mb-4 w-11/12 mx-4   md:mx-6 ">
      <button
        className={`w-full bg-gray-200  p-4  text-center md:text-left
        hover:bg-gray-300 hover:text-gray-900
         font-bold text-2xl rounded-t-lg ${
          isOpen ? 'text-gray-900' : '  text-gray-600'
        }`}
        onClick={handleClick}
      >
        {title}
      </button>
      {isOpen && <div className="p-4 md:mx-6 mx-0 w-full md:w-11/12 rounded-b-lg bg-white shadow-md">{children}</div>}
    </div>
  );
};

function IntegerInputField({ label, valueChanged, defaultValue = 0 }) {
  const [value, setValue] = useState(''+defaultValue);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Allow only empty string or integer values
    if (/^\d*$/.test(inputValue)) {
      setValue(inputValue);
      const parsedInt = parseInt(inputValue);
      const intValue = Number.isInteger(parsedInt) ? parsedInt : defaultValue
      //const intValue = inputValue == '' ? defaultValue : parseInt(inputValue, defaultValue);
      //console.log("new integer value "+intValue+", "+inputValue)
      if (valueChanged) {
        valueChanged(intValue); // Pass integer value or default on error
      }
    }
  };

  return (
    <span className="">
      <label htmlFor="integer-input" className=" mb-2">
        {label}
      </label>
      <input
        id="integer-input"
        type="text"
        value={value}
        onChange={handleChange}
        className="mx-4 px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder={""+defaultValue}
      />
    </span>
  );
}



/**
 * This is a responsive line break <br /> which doesn't show up on mobile
 * screens and only on tablet, desktop and above.
 * use this when you want to use full screen of mobile phone for reading text
 */
const BRR=()=>{
  return (
    <span className="hidden md:block"></span>
  )
}

/**
 * This is a responsive space which doesn't show up on mobile
 * screens and only on tablet, desktop and above.
 */
const SPACE=()=>{
  return (
    <span className="hidden md:inline-block">&nbsp;</span>
  )
}

/**
 * This is 2 responsive spaces which doesn't show up on mobile
 * screens and only on tablet, desktop and above.
 */
const SPACE2=()=>{
  return (
    <span className="hidden md:inline-block">&nbsp;&nbsp;</span>
  )
}




export {ExpandCloseTab, IntegerInputField, BRR, SPACE, SPACE2};