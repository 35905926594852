
/** returns bold text */
function txb(text){
    return (
        <span className="font-bold md:font-semibold font-mono "> {text} </span>
    );
}
/** returns blue bold text */
function txbb(text){
    return (
        <span className="font-bold md:font-semibold font-mono  text-blue-600">
             {text} </span>
    );
}

/** returns red bold text */
function txrb(text){
    return (
        <span className="font-bold md:font-semibold font-mono  text-red-600">
             {text} </span>
    );
}

/** returns header bold text which will be larger in size */
function txhb(text){
    return (
        <span className="text-2xl font-bold font-mono "> {text} </span>
    );
}

/** returns header bold blue text with blue-100 
 * background which will be larger in size 
 */
function txhbb(text){
    return (
        <span className="text-lg md:text-2xl  font-bold font-mono
        text-blue-600 bg-blue-50 
        px-4 py-2  mb-4 rounded-t-lg "> {text} </span>
    );
}


export {txb, txbb, txhb, txhbb, txrb}