import NavbarTop from "../ui_components/navbar_top";
import { useParams } from 'react-router-dom';
import React, { useEffect, useState, useMemo } from 'react';
import Editor from '@monaco-editor/react';
import classNames from 'classnames';
import { JAVA, ALL_LANGUAGES, PYTHON, errorMsg, handleCtrlSave, homeUrl, success, isBlankOrNullString } from "../utils/common_utils";
import { getAuth, getCurrrentLanguage, getUIState, isLoggedIn, isValidUser, saveCurrentLanguage, saveUser } from "../login/auth_utils";
import { getJsonDataPostRequest, getTextFileAsString, uploadStringToS3, uploadTextToS3 } from "../utils/network_utils";
import { isFailedTestExecutionStatus, isTestRunning, testExecutionStatusToString } from "./question_utils";
import IndefiniteProgressBar from "../ui_components/indefinite_progress_bar";
import LoginModal from "../login/email_login_modal";
import FontSizeChanger from "../ui_components/font_size_changer";
import { LeftIcon } from "../ui_components/common_icons";
import { VisitPremiumSection } from "../premium/premium_discounts";
import { SelectLanguageDropdown } from "./select_language_dropdown";
import { BuyPremiumMotivationQuestionsPage, BuyPremiumMotivationSmall1 } from "../premium/buy_premium_motivation";
import { BRR } from "../ui_components/common_ui_components";

function QuestionCodeSubmission({question}) {
//const QuestionCodeSubmission = () => {
  const [code, setCode] = useState('');
  const [output, setOutput] = useState('');
  const [loading, setLoading] = useState(false);
  const[language, setLanguage] = useState(getCurrrentLanguage()); 
  const[error, setError] = useState('')
  const[message, setMessage] = useState('')
  const [metadata, setMetadata] = useState({})
  const[loggedIn, setLogin]= useState(false)
  const uiState=getUIState()
  const availableLanguages = ALL_LANGUAGES.filter(
    str=> isBlankOrNullString(question.languages) 
    || question.languages.indexOf(str)>=0)
    
  //console.log(question)  
  const [fontSize, setFontSize] = useState(16);
  const editorOptions = useMemo(() => ({
    wordWrap: 'on',
    fontSize,
    fontFamily: 'Monaco',
    minimap: { enabled: false },
    overviewRulerLanes: 1,
    autoIndent: true,
    hideCursorInOverviewRuler: false,
  }), [fontSize]);

  /** run id will be used for rejecting older run code requests when a new one comes in */
  //const [runId, setRunId] = useState(0)
  var runId=0
  const  { questionId }  = useParams();
  
 // console.log("question id "+questionId)
  const auth = getAuth()

  function languageChanged(newLanguage){
    // first save then update
    handleSave(() => {
    console.log("new language is "+newLanguage)
    if(isBlankOrNullString(newLanguage) || question.languages.indexOf(newLanguage)<0) return
    setLanguage(newLanguage)
    saveCurrentLanguage(newLanguage)
    fetchSubmissionMetadata({currentLanguage : newLanguage , createNewSubmission:false})
  });
  }

  function resetMessages(){
    setMessage("")
    setError("")
    setOutput("")
  }

  const handleResetDefault = async () => {
    // Make API request to run code and get output
    resetMessages()
    setMessage("going to reset code to default ...")
    fetchSubmissionMetadata({currentLanguage : language, createNewSubmission:true})
  
    //fetchSubmissionMetadata(true)
  }
  
  const handleSave = async (callback=() => {}) => {
    //function handleSave(){
    // Make API request to run code and get output
    resetMessages()
    setMessage("Saving code ...")
    setLoading(true)
    console.log("saving code")
    console.log(metadata.presignedGetUrlCode)
    //console.log(metadata.presignedPutUrlCode)
   // uploadStringToS3(metadata.presignedPutUrlCode, code, "Solution.txt", function(data){
    uploadTextToS3(code, metadata.presignedPutUrlCode, function(data){
      setLoading(false)
      setMessage("code saved ...")
      //console.log(" data file upload : "+data)
      if(!success(data.status)){
         let msg = data.message || "error occured in saving file... please try again";
         setError(msg) // setOutput(msg)
      }
      //else if(callback) 
      if(success(data.status) && typeof callback === 'function')  callback();
    })
  }

  const handleRunSampleTests = async () => {
    submitRunRequest(0)
  };

  const handleSubmit = async () => {
    submitRunRequest(1)
  };

  function submitRunRequest(executionType = 0){
    // save the code before execution
    resetMessages()
    setLoading(true)
    setMessage("saving code ..") // setOutput("saving code")
    uploadTextToS3(code, metadata.presignedPutUrlCode, function(data){
    setMessage("")
    if(!success(data.status)){ 
      setOutput(data.message || "error occured in saving file... please try again")
      setLoading(false)
    }
    else{
      //console.log(metadata.presignedGetUrlCode)
      setOutput("Code saved .. going to submit for test execution")
      // if save is successful then submit code for execution 
      let url = homeUrl()+"questionsApi/submitCodeForTesting"
      let submissionData = { auth:auth, questionId: questionId,
      submissionId: metadata.submissionId, executionType: executionType }
      getJsonDataPostRequest(url, submissionData, function(response){
        console.log("code submitted "+JSON.stringify(response))
        if(success(response.status)){
          setOutput("Code submitted for test execution ... \n It can take 1 or 2 minutes to run all tests ...")
          runId=response.runId //setRunId(response.runId)
    // if code is submitted for execution then keep fetching status until run finishes/error/timeout or a new request comes. 
          updateRunStatus(runId, executionType)
        }
        else{
          setLoading(false)
          let msg = response.message || "Error occured in submitting code for test execution, please try again."
            setError(msg) // setOutput(msg) 
            console.log(msg)
        }
    })  
      }
      
    })  
    
  }

 /**
  * used to fetch execution status every 3 sec and show as output
  */
  function updateRunStatus(currentRunId, executionType){
    console.log(" going to start fetching test execution status, loading "+loading)
    var startTime = new Date().getTime()
    const intervalId = setInterval(() => {
      console.log("running interval "+intervalId+" runId "+runId+" current run id "+currentRunId)
      
      // write code to fetch submissions and end timer based on that  
    let url = homeUrl()+"questionsApi/getSubmissionStatus"
    let data = { auth:auth, questionId: questionId,
      submissionId: metadata.submissionId, executionType: executionType, runId: runId }

    getJsonDataPostRequest(url, data, function(response){
      if(runId!=currentRunId) {
        clearInterval(intervalId)
        return
      }
      console.log("code execution status response : \n "+response.data.executionStatus+": "+response.data.executionMessage)
      if(success(response.status)){
        if(isFailedTestExecutionStatus(response.executionStatus))
         setOutput(testExecutionStatusToString(
          response.data, language))
        else setOutput(testExecutionStatusToString(
          response.data, language) || (" <div class='italic text-blue-600'> still fetching ... </div>\n it can take 30 seconds to a few minutes")) 
        // unless test execution has not yet started or is still active i.e test execution has ended in success or failure then stop the interval  
        if(!isTestRunning(response.data.executionStatus)) {
          console.log("test not running anymore")  
          setLoading(false)
          clearInterval(intervalId); 
        }
      }
      else{
        let msg = response.message || "Error occured in fetching details."
          setError(msg) // setOutput(msg) 
      }
      
  })
    // 90 seconds timeout
    if (new Date().time-startTime>90*1000) {
      // ending the fetch
      setLoading(false)
      clearInterval(intervalId);
      console.log("stopped interval "+intervalId)
    }
    }, 3000,3000); // second 3000 ms is initial delay after which interval starts, needed to add this because initial reset to test submission data in dynamodb is eventually consistent, so needed to read after a few seconds.
  }

 function fetchSubmissionMetadata({currentLanguage = language, createNewSubmission = false}){
    if(!auth|| !auth.userId||auth.userId<0){
    setError("Please login and try again")
    return
  }
  setLoading(true)
  resetMessages()
  let data = {
      auth:auth,
      questionId: questionId,
      language: currentLanguage,
      createNewSubmission: createNewSubmission
  }

  console.log("fetching the solution code metadata "+JSON.stringify(data));
  let metadataUrl = homeUrl()+"questionsApi/getQuestionSubmissionMetadata"

  getJsonDataPostRequest(metadataUrl, data, function(data){
    if(success(data.status)){
      if(isValidUser(data.user)) saveUser(data.user)
      // using else because normal variable will forget values
      setMetadata(data.metadata)
      console.log("submission id "+data.metadata.submissionId+" create new submission "+createNewSubmission)
      
      // console.log("presigned get url :"+data.metadata.presignedGetUrlCode+",\n\n metadata:  "+JSON.stringify(data.metadata))
      getTextFileAsString(data.metadata.presignedGetUrlCode, 
        function(codeText){
          setLoading(false)
         // console.log("code text received ::\n "+codeText)
          if(codeText.length>0){
            setCode(codeText)  
            if(createNewSubmission)
              setMessage("reset to default code done ...")
            
          }
          else {
            setError("Some error occured in fetching code \n please try again or reset code")
            setCode("// no code found, maybe try to fetch again")
          }
        })
    }
    else{
      setError(data.message || "Error in fetching code, please try again")
      setLoading(false)
    }
      //console.log("response received")
      //console.log(data);
  });
  }

  const handleKeyDown = (e) => {
    // handling ctrl+s
    handleCtrlSave(e, function(){
      console.log("\n ignoring ctrl+s click because handling it was giving error,\n and ignoring is better than accidently opening a save page dialog.")
    });
  };

// loading metadata and code 
useEffect(() => {
  setLogin(isLoggedIn())
  console.log("question is available in these languages "+question.languages)
  console.log("available languages "+JSON.stringify(availableLanguages))
  var lang=language
  if(isBlankOrNullString(language) || question.languages.indexOf(language)<0){
    lang=availableLanguages[0]
    setLanguage(lang)
    saveCurrentLanguage(lang)
  }
  fetchSubmissionMetadata({currentLanguage : lang, createNewSubmission:false})
  // listening to key clicks
  document.addEventListener('keydown', handleKeyDown);
  return () => {
    document.removeEventListener('keydown', handleKeyDown);
  };
   
}, []); // empty bracket means there are no dependencies which will make useEffect run only once and not re run again and again

  return (
  <div className="mr-4">
  { uiState==0 &&
      <div className="flex-item text-center ml-8
       md:mt-20 md:h-1/3">
        <div className="text-xl font-medium text-gray-600"> 
        <BRR /> <br />
        
            You need to be logged in to submit code <BRR /> <br />
            <LoginModal />
        
        </div>
        
      </div>
  } 

   {question.type==1 && (uiState==1||uiState==2) &&
   <BuyPremiumMotivationSmall1 />
   /*<div className="flex flex-col justify-center mt-16 
   text-2xl text-gray-500 font-medium text-center
    bg-orange-100 py-8 px-2 m-12">
    <div> 
      You need to be a Premium Member <br />
      to attempt this question
    </div>
    <br />  <br />
    <VisitPremiumSection />
    </div>
    */
   }  

{((question.type==0 && uiState!=0) || uiState==3) &&
<div className="flex flex-col md:h-screen"> 
<div className="flex flex-row justify-around px-4 ">
 <span className="flex-item text ml-4 font-bold mb-2">
    { //false && 
      <SelectLanguageDropdown language={language} availableLanguages={availableLanguages} onLanguageChange={languageChanged} />
    //JAVA-11 language.toUpperCase()
  } </span>

  <span className='mr-16 ml-32'>
  <FontSizeChanger fontSize={fontSize} setFontSize={setFontSize}  />
  </span>
  
  <span className=" ml-16   ">
   <button
      className=" px-3 py-1 mb-1 text-sm border border-gray-500 rounded text-gray-500 hover:bg-gray-600 hover:text-white focus:outline-none  focus:ring-gray-500"
      onClick={handleResetDefault}
    >
      Reset to default code
    </button>
    </span> 

   
  </div>

  { loading && <div className="m-4 text-center items-center">
       <span className="  z-50"><IndefiniteProgressBar />  </span>  
    </div>
  }

 {loggedIn && <div className="flex-item md:h-2/3 "> 
    <Editor
     // height="450px"
      defaultLanguage={language}
      language={language}
      defaultValue="// Fetching code ... please wait .."
      value={code}
      onChange={setCode}
      options={editorOptions}
      /*
      {
        wordWrap: 'on',
        fontSize: fontSize,
        minimap: { enabled: false },
        overviewRulerLanes: 1,
        autoIndent: true,
        hideCursorInOverviewRuler: false,
      }
      */
    />
  </div>
}

    <div className="flex-item md:h-1/3 mt-2"> 
    <div className="flex flex-row justify-between">   
    <div className="mt-4">
    <span className="text-xl font-bold">Output:</span>  
    {loading && <span className="  z-50"> &nbsp; &nbsp; <IndefiniteProgressBar />  </span>  }
    </div>
    <div className="ml-auto mt-2 ">    
      <button className="bg-gray-400 text-white rounded-lg px-4 py-2 mx-4 hover:bg-gray-600" onClick={handleSave}> Save </button> 

    <button className="bg-indigo-500 text-white rounded-lg px-4 py-2 mx-4 hover:bg-indigo-600" 
    onClick={handleRunSampleTests} disabled={loading}>
       Run </button> 

    <button className="bg-green-600 text-white rounded-lg px-4 py-2 mx-4 hover:bg-green-700" 
    onClick={handleSubmit} disabled={loading}> Submit </button> 
    </div> 
    </div> 

   {(error || message || output) &&
    <div className=" mb-16 mt-2"> 
    <div className="bg-gray-200 p-4 mr-2 rounded-lg">
      <div>
      { error &&
      <div className="text-red-700 text italic px-4 py-2">{error}</div>
      }
      {message &&
      <div className="text-indigo-700 text px-4 py-2">{message}</div>
      } 
      </div>

      <div className="whitespace-pre-wrap text-gray-900"
      dangerouslySetInnerHTML={{ __html: output }} />
    </div>
    </div>
  }
    </div>
       
  </div>
  }
  </div>  
    
  );
  
}

 export default QuestionCodeSubmission;