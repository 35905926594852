import React, { useState, useEffect, Children } from 'react';

function PreparationLinkHomePage(){
    const preparationPage = (e) => {
        e.preventDefault();
        console.log("click occured")
        window.location.href='/roadmap'
    };
    return (
        <a className='flex flex-col m-4 px-8 py-4
         text-white bg-orange-600 text-xl font-semibold
         text-center font-mono  rounded-md z-0 shadow-lg
         cursor-pointer leading-10
         hover:text-white hover:bg-orange-700'
         
         href="/roadmap" >
         <span className='text-2xl font-bold'>7 Days</span>
         Preparation Plan
         <button className='bg-white mt-4 text-gray-600
         rounded-full  hover:text-gray-800' 
         >
            Start</button>
        </a>
    )
  //  bg-purple-600 hover:bg-purple-700
}

export {PreparationLinkHomePage}