import React, { useState, useEffect } from 'react';
import { ONE_DAY, ONE_HOUR, errorMsg, homeUrl, rearrangeArray, success } from '../utils/common_utils';
import IndefiniteProgressBar from "../ui_components/indefinite_progress_bar";
import { getAuth, getCurrentServerTime, getUIState, getUser, isLoggedIn, isValidUser, saveHomePageResponseData } from '../login/auth_utils';
import { getJsonDataPostRequest } from '../utils/network_utils';
import { BuyPremium} from './premium_discounts';
import { PremiumUserUI } from './premium_user_ui';
import LoginModal from '../login/email_login_modal';
import  { NavbarTop, NavbarWithOnlyHome } from '../ui_components/navbar_top';
import { BRR, IntegerInputField, SPACE, SPACE2 } from '../ui_components/common_ui_components';
import { LinkUIBlueBold } from '../ui_components/common_links';
import { txbb, txhb } from '../ui_components/text_style_components';

function BulkSubscriptionHome() {
  const[peopleCount, setPeopleCount] = useState(50)  
  useEffect(() => {
      
  }, []); // empty bracket means there are no dependencies which will make useEffect re run

  return (
  <div>
  
  {<NavbarTop />
  }
    <div className='pt-20'></div>
    <div className = "flex flex-col w-11/12 break-words items-center  justify-center mt-6 mx-4">
      <p>
        <span className="text-6xl 
        font-sans text-gray-800">
          Bulk Subscription</span>
      </p>
       <div>
        <br /><br />
        
        {/* introduction div starts*/}
        <div className=''>
          <p>
            Bulk subscription is useful for colleges, coaching institutes and individial teachers or trainers <BRR />
             who are looking to train their students for low level design interviews. 
             
          </p>
          <br /><br />
            Now you can buy CodeZym premium subscription in bulk at discounted prices.
            <br /><br />   
        <SPACE2 /> - USD 12/- per person valid for 365 days. 
        <br />
        <SPACE2 /> - Minimum order is 20 subscriptions i.e. USD 240/-
        <br /><br /><BRR />
        </div>
        {/* introduction div ends */}


        {/* Payment div starts*/}
        <div className=''>
            {txhb("Payment")}
            <br />
        
            <SPACE2 /> <IntegerInputField label="Enter number of subscriptions" 
        valueChanged={setPeopleCount} defaultValue={50}
        />
        <br />  <br />
       <SPACE2 /> Total Amount @ USD 12/- per subscription:  
       <span className='font-bold text-blue-600'> USD {12*peopleCount}/- </span>
        
        <br />  <br />
        <div className='break-all'>
        <SPACE2 /> Please pay using below Link: <br /> 
        <SPACE2 />  <LinkUIBlueBold url= {`https://www.paypal.com/paypalme/yogpatri/${12*peopleCount}`} 
        text= {`https://www.paypal.com/paypalme/yogpatri/${12*peopleCount}`}/> 
        </div>
        </div>
    {/* Payment div ends*/}

        <br /> <br />
        {/* After payment div starts*/}
        <div>
            {txhb("After Payment")} <br />

        <SPACE2 /> After paying, please share with us the following at {txbb('contact@codezym.com')}
        <br /> <br />  
        <SPACE2 /> 1. PayPal payment transaction receipt or screenshot.
        <br /> 
        <SPACE2 /> 2. List of email ids of people whom you want to include in the subscription.
        <br /> <br />  <br /> 
        We will enable premium account for everyone in your subscription list and send them email within 24 hours or payment.

        <br /> <br />  
        
        </div>
         {/* After payment div ends*/}
    </div>
<div>
<br /> <br />
</div>
    
</div>
</div>
  );
        
}

/**
 * single line message showing buy bulk subscription at USD 12 per person, 
 * will redirect to bulk subscription home page.
 */
function BulkSubscriptionOneLine() {
    function openBulkSubscriptionPage(){
        window.location.href='/wholesale'
    }
  return (
    <span>
      <span className='text-blue-600 underline
       hover:text-blue-800  cursor-pointer'
      onClick={openBulkSubscriptionPage}>
        Buy Bulk Subscription</span>  at just USD 12/- per Person  
    </span>
  )
}

export {BulkSubscriptionHome, BulkSubscriptionOneLine};

