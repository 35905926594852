import React, { useState, useEffect, Children } from 'react';
import { errorMsg, homeUrl, rearrangeArray, success } from '../utils/common_utils';
import IndefiniteProgressBar from "../ui_components/indefinite_progress_bar";
import {NavbarTop} from '../ui_components/navbar_top';
import { YoutubeVideoDisplay } from '../ui_components/youtube_video_display';
import { LinkUI } from '../ui_components/common_links';
import { txb, txbb, txhb, txhbb, txrb } from '../ui_components/text_style_components';
import { BRR, ExpandCloseTab, SPACE, SPACE2 } from '../ui_components/common_ui_components';
import { isPremiumUser } from '../login/auth_utils';
import { VisitPremiumSection } from '../premium/premium_discounts';
import { ParkingLotQ01UI, Q03CustomerIssueResolutionUI, Q04InventoryManagementUI, Q06HitCounterUI, Q07ParkingLotStrategyPatternUI } from './prep_plan_helper_ui_classes';
import '../index.css'

function FirstPreparationPlan() {
   const prefix="roadmap1day"
   const [isPremium, setPremium] = useState(isPremiumUser());
    useEffect(() => {
    }, []) // empty bracket means there are no dependencies which will make useEffect re run
    
    return (
      // w-full max-w-full overflow-x-hidden is used so that component fills
      // ful width of parent and no horizontal scroll must be there.
    <div className = "w-full max-w-full overflow-x-hidden items-center justify-center">
      <NavbarTop />
     <div className='flex flex-col w-full max-w-full overflow-x-hidden mr-4 md:mx-6 items-center justify-center md:text-lg md:font-medium md:font-mono'>
    
      <h2 className='text-2xl font-mono text-center mt-24 mb-8 font-bold md:font-semibold m-6
      bg-blue-100 px-6 py-3 text-blue-700 rounded-t-md '>
       How to Prepare for <br /> Low Level Design Interviews</h2> 
       {/*    Preparation Roadmap for LLD Interviews  */}
     <Introduction /> <br />  

     {/* buy premium div starts !isPremium . 
     I am making premium page free, it will act as motivation to buy premium instead */} 
     { false  &&
        <div className="flex flex-col w-3/4 justify-center mt-4 
        text-2xl text-gray-600 font-medium text-center
         bg-yellow-200 p-8 ">
         <div className=' text-xl'> 
           Only Premium Members  <br />
           Can access Complete Preparation Plan
           
         </div>
         <br />  <br />
         <VisitPremiumSection />
         </div>
        
     } {/* buy premium div ends */}

     {/* daywise schedule div starts */}
    { // there should be no horizontal scroll and content must fit within page width
     <div className='felx felx-col w-full max-w-full overflow-x-hidden items-center justify-center text-lg '>
     <ExpandCloseTab title="Day 1 - Warming Up" 
        storageKey = {prefix+"1"} children={Day1()} />

      <ExpandCloseTab title="Day 2 - Strategy Pattern" 
        storageKey = {prefix+"2"} children={DayStrategyPattern()} /> 

      <ExpandCloseTab title="Day 3 - Observer Pattern" 
        storageKey = {prefix+"3"} 
        children={DayObserverPattern()} />  

      <ExpandCloseTab title="Day 4 - Factory and Singleton Pattern" 
        storageKey = {prefix+"4"} 
        children={DayFactoryAndSingletonPattern()} /> 

      <ExpandCloseTab title="Day 5 - FlyWeight Pattern" 
        storageKey = {prefix+"5"} 
        children={DayFlyweightPattern()} /> 

      <ExpandCloseTab title="Day 6 - State Pattern" 
        storageKey = {prefix+"6"} 
        children={DayStatePattern()} /> 
      
      <ExpandCloseTab title="Day 7 - Multi-threading" 
        storageKey = {prefix+"7"} 
        children={DayMultithreadingAndStrategyPattern()} />

     <br /> 
     <div className='text-2xl font-semibold font-mono
      text-blue-600 bg-blue-50 px-6 py-3 text-center '>
      Best of Luck
    </div>
    <br /> 
    
    <div className='flex flex-row-responsive w-full 
    text-center justify-center items-center'>
    <img className='w-1/2'
    src="https://public-items-1.s3.ap-south-1.amazonaws.com/codezym/seo_images/preparation-roadmap-5.jpg"
      />
    </div>
    </div> 
   }
    {/* daywise schedule div ends */}

     <br />  <br /><br />
     </div>
     </div>
  );
}

function Introduction(){
  return (
    <div className='flex flex-col w-full max-w-full overflow-x-hidden px-6 items-center justify-center leading-6'>
     {/* ---- First row starts ------- */}
    <div className='flex flex-row-responsive items-center justify-center'>
    <div className='text-sm md:text-lg md:font-medium md:font-mono '>
    
    {
      /*
      This is a roadmap to get you ready for your  <br />
    Low Level Design Interviews. <br />  <br />
      */
      <div > 
        <div className='leading-relaxed'>
        This is a 7-Day plan to help you prepare  <BRR />
         for {txbb("Low Level Design Interviews")} using <BRR /> common design patterns. <br /> <br />
         It will guide you to  clear LLD rounds of <BRR /> 
         Microsoft, Amazon, Flipkart and other  <BRR /> 
         top product  companies for SDE-1, SDE-2 and <BRR />  
        Senior Software Engineer positions.
          <br /> <br /> 
       </div>
          
<p>{txb('Languages:')} As of now {txbb('Java')} and {txbb('Python')}
   are supported.   <br /></p><BRR /><BRR />

      {/*
       We {txbb("don't")} intend to cover the comprehensive   <br />
       Low Level Design for all the components and     <br />
       hundreds of classes in large systems like    <br />
       Parking Lot, Food Ordering app and other systems.  <br /> <br /> 
      */}
      
    
      {
       /*
        2. YouTube video solutions will also teach you how to  <br />
       &nbsp;&nbsp; explain your solution to the interviewer step by step. 

       Both the theory and YouTube video solutions will   <br />
       specifically deep dive into the core features    <br />
       for the problem statement which interviewers will   <br />
       expect to discuss with you in a LLD interview. <br />  <br />
    */
    } 
       
      </div>
    }
    
   
    {/**  
    Some questions are free, but rest are premium. <br />
     So go ahead and
    <LinkUI url="/premium" text="Buy Premium" />
    Its cheap and totally worth it. <br />
  */}
    
    </div>
    <div className=' flex flex-col items-center justify-center md:mx-6 '>
    
    <YoutubeVideoDisplay videoId="LLIPmP6p4RE" 
    /><BRR /><BRR /><BRR />
    
    
    
    </div>
  
    </div>
     {/* ---- First row ends ------- */}

    {/* false &&
    <div className='flex flex-row items-center justify-center'>
    <div className='font-medium font-mono '>
    <div className='bg-yellow-200 rounded-lg p-4 px-16 py-6'>
    If you have an interview {txbb("tomorrow,")} then only read theory    
    from below. <br /> In {txbb("an hour")} you will know about  different type
    of questions and <br /> design patterns used to solve them.
    </div>
    <br />
      </div>
    </div>  
    */}
   
<br />
    {/* ---- "we won't be covering" row starts ------- */}
    <div className='flex flex-row-reverse-responsive items-center justify-center 
     '>
    <div className='flex flex-col items-center justify-center md:px-16 '>
    <YoutubeVideoDisplay videoId="knyFrpNfEp4" /> 
    <BRR /> 
   
  {/*  <YoutubeVideoDisplay videoId="W0OHFKXxy3o" />  */}
    </div> 


    <div className='flex flex-col items-center justify-center '>
      <div>
    We {txrb("won't")} be covering every detail of large systems <BRR />  like movie ticket booking system or food ordering app   <BRR />
    which can have hundreds of components and classes.
    <BRR /> <br /> <br /> 

      1. Instead we will deep dive into the {txbb('core features')}    <BRR />
      <SPACE2 />  of these questions which interviewers expect  <BRR />
      <SPACE2 />  to discuss with you in a LLD interview. <br /> <br />
      {/*
       2. YouTube video solutions will discuss different <br />
       &nbsp;&nbsp;  approaches and data structures used for  <br />
       &nbsp;&nbsp;  the solution while comparing their pros and cons.  <br />
       &nbsp;&nbsp;  This will help you answer {txbb('counter questions')}   <br />
       &nbsp;&nbsp; in the interview. <br /> <br />
      */}
       2. YouTube videos will also teach you how to explain <BRR />
       <SPACE2 />  your solution to the interviewer {txbb('step by step.')} 
       <br /> <br />
       </div>
    </div> 

    </div>
     {/* ---- "we won't be covering" row ends ------- */}


    <br /> 
    {/* ---- steps to crack row starts ------- */
  <div className='flex flex-row-responsive items-center justify-center'>
 
  <div className='mx-6 '>
    <div className=' font-bold text-xl mb-6'>How to Crack Low-Level Design Interviews? <BRR />  </div>
    <p className=''>Here are the 
         {txbb(' four things')} you need to learn, <BRR /> to crack a low level design interview round.</p> <br />
         <div className='md:leading-loose'>
        1. How to specify core features of the question <br />
       {/*  &nbsp; &nbsp;  being discussed. <br /> */}
        2. How to break your solution in multiple classes <br />
        3. Usage of design patterns to solve the problem <br />
        4. How you handle multithreading  
        <span className='text-gray-700 text-sm font-medium font-mono'> (optional sometimes) </span> <br /> <br />
  </div>
  {/*<div>
  <br />
  Try to complete Coding for questions even if  <br /> 
    you have to watch the solution multiple times, 
    <br /> because that will make you comfortable with  <br /> 
     the different data structures <br /> especially 
      the thread safe data structures and edge cases 
     <br /> which will be useful when interviewer tweaks the question a little.
    <br />  <br />
  </div> */}
  </div>

  <div className=' flex flex-col md:mt-4 md:mx-6'>
 {/* <YoutubeVideoDisplay videoId="W0OHFKXxy3o" /> */}
 <YoutubeVideoDisplay videoId="rzbEsMn-roE" /> 
  </div>

  </div>
     
 /* steps to crack  row ends */
 }

    <div className='flex flex-row items-center justify-center'> 
    
    <div className=' '>
   {/* If you can devote more time like {txbb("4-5 hours")} then <br />watch all  the design pattern videos as well.
    <br /> <br />
    If you can spare even more time, then complete this <br />  whole roadmap and practice all questions. 
    <br /> <br />
    */}
     {/* We will start with some short videos and simple questions
      <br /> to get you in the  preparation mode and 
       then <br />  one by one cover most commonly asked topics. 
       <br /><br /> If you are not getting interview calls,
       <br /> then watch the video on the right for advice.
      <br /> <br />
     */}
      <div className='md:leading-loose justify-center items-center text-center'>
        <br />
    {txbb("Plan")} is to start each day with a few short videos 
    to get you in preparation mode, <br />  
    and then do one coding question covering one or more design patterns.
    <br />
    </div>
    {/* <br /> <br />
     <i className='text-gray-700 text-sm'>If you understand Hindi then I recommend you to watch the Hindi videos <br />
     you may find explanation a bit better in them. </i>
    */}
      <br />
    </div>
    </div>


    </div>
    );
}

function Day1(){
    return (
    <div className='flex flex-col  items-center justify-center'>
      
    {/*first row starts */}  
    <div className='flex flex-row-responsive items-center justify-center'>
    <div className='md:mr-6'>
        There are {txbb('two types')}
         of low level design interview formats: <br />
        1. 75 to 90 minutes of machine Coding <br />
        2. 45-60 minutes of face to face discussion <br />
        <br />
       Watch the adjacent Youtube Video for details. 
    </div>
    <div className=' flex flex-col mt-4 md:m-6'>
    <YoutubeVideoDisplay videoId="dVjd6gFUEsA" 
    />
    
   {/* <YoutubeVideoDisplay videoId="br-3o1i3qLI" /> */}
    </div>
    </div>  {/*first row ends */}
  
 {/* ---- importance of coding row starts ------- */
  <div className='flex flex-row-reverse-responsive items-center justify-center'>
  <div className=' flex flex-col mt-4 md:px-16'>
  <YoutubeVideoDisplay videoId="ef99Ejb3B40" /> 
  <YoutubeVideoDisplay videoId="hWac3sK5BBo" />
   
  
  {/* <YoutubeVideoDisplay videoId="S5GUPErF-iw" /> */}
  </div>
 {/* <div className='mx-6  font-mono'>
    <p className='font-medium font-mono'>Following are the 
         {txbb(' four things')} you need to learn, <br /> to crack a low level design interview round.</p> <br />
         <div className='leading-relaxed'>
        1. How to specify core features of the <br />
         &nbsp; &nbsp; question being discussed. <br />
        2. How to break your solution in multiple classes <br />
        3. Usage of design patterns to solve the problem <br />
        4. How you handle multithreading (optional sometimes) <br /> <br />
  </div>
  <br />
 */}
 <div>
 During LLD Interviews, it is not enough to 
  just come up  <BRR />
  with a solution.  Interviewers at top product companies <BRR />
  tweak the questions and add {txbb('custom scenarios.')} <BRR />
  You should be able to change your design to handle those. <br /> <br />
  You are expected to {txbb('defend your design decisions')} <BRR />
  like why did you use a certain design pattern or <BRR />
   kept certain data inside a given class. <BRR />
  You can learn all this only through practicing questions. <br /> <br />
  Most candidates who clear FAANG/MAANG companies, practice  <BRR />
  machine coding of questions rather than just watching videos.
  </div>
  {/*Try to complete Coding for questions even if  <br /> 
    you have to watch the solution multiple times, 
    <br /> because that will make you comfortable with  <br /> 
      different data structures  especially <br /> 
       thread safe data structures and synchronization. 
     <br /> This will be useful when interviewer tweaks the question.
     */}
    <BRR /><br />
  </div>
  
     
 /* importance of coding row ends */
 }
 
<br />  <BRR /> 

    {/*second row starts */}  
    <div className='flex flex-row-responsive  items-center justify-center'>
   {/* <div className=' flex flex-row mt-4 mx-6'>
    <YoutubeVideoDisplay videoId="YMAwgRwjEOQ" 
    /> 
    </div> */}
    <div className='md:mx-6 md:leading-loose md:justify-center md:items-center md:text-center'>
    These are the {txbb("four")} most common design patterns asked in LLD interviews.  <br />
    {txbb('Strategy,')} {txbb('Observer,')} {txbb('Factory')} and {txbb('Singleton')}   <br />
   
       We will practice them first and then move on to other design patterns.
    </div>
    
    </div>  {/*second row ends */}
<br /> <br /> 


    {/* practice problem row starts */}  
   
   {/* <div className='flex flex-row items-center justify-center'>
    <div className='mx-12'>{txbb("Practice Problem")}  <br />
    {txb("Design a Hit Counter/Webpage Visits Counter - Multi-Threaded")} <br />
    <LinkUI url="https://codezym.com/question/6" />  <br />
    This is an easy question, yet it gives you a taste of <br />
    basic data structures to use in a multithreaded environment. 
    <br /> This makes it an ideal question to put you in preparation mode for <br /> your low-level design interviews. <br /> <br />
    </div>
    <div><YoutubeVideoDisplay videoId='jU7I2-jWJ8k' /></div>
    </div>  
    */}
    <div className='flex flex-row-responsive'>
    <ParkingLotQ01UI />
    </div>
    {/* practice problem row ends */}

    </div>
    );
}


function DayStrategyPattern(){
  return (
  <div className='flex flex-col w-full items-center justify-center'>
  {/*first row starts */}  
  <div className='flex flex-row-responsive items-center justify-center'>
  <div className='md:mr-6'>
    {txhbb('Strategy Design Pattern')} <br /> <br />

    Strategy Pattern is used when we need different algorithms 
    <BRR /> for the same functionality.  Some examples are : <br />
    
      1. Different moves in chess: straight, diagonal, 2+1 move <br />
      2. different ways to order list of items <BRR />
     <SPACE2 /> e.g. by price, rating, most ordered etc  
      
  </div>
  <div className=' flex flex-col mt-4 md:ml-6'>
  <YoutubeVideoDisplay videoId="iJkWqHw2PJc" />
  {/*
  <YoutubeVideoDisplay videoId="R_AadNfw0k8" />
    
  <YoutubeVideoDisplay videoId="l70m-wRXtcc" />
  */}
 </div>
  </div>  {/*first row ends */}
  <BRR />  <br /> 
  {txbb("Practice Problem")}  <br />

  {/* practice problem row starts */}  
  <Q07ParkingLotStrategyPatternUI />
   {/* practice problem row ends */}
 
  </div>
  );
}

function DayMultithreadingAndStrategyPattern(){
  return (
  <div className='flex flex-col w-full items-center justify-center'>

  {/*first row starts */}  
  <div className='flex flex-row-responsive items-center justify-center'>
  <div className='md:mr-6'> <br /> 
    {txhbb('Multi-Threading')} <br /> <br />
    In LLD interviews, after design patterns, discussion moves on to multi-threading. <BRR /> 
    For these question you have to use synchronization, locks and thread safe data structures. <br /> <br />
    If you are using {txbb("Java")} then you have access to thread safe data structures   <BRR />  like  AtomicInteger, ConcurrentHashMap, ConcurrentLinkedDequeue etc.
     <br />  <br />
     <div className=''>Below are {txbb("Practice Problems")}  </div>
      
  </div>
  </div>  {/*first row ends */}
  <br />  
  <Q06HitCounterUI />
  <br /> <br />
  <Q04InventoryManagementUI />
  <br /> <br />
  <Q03CustomerIssueResolutionUI />

  <br /> <br /> <br />

    
  </div>
  );
}

function DayFactoryAndSingletonPattern(){
  return (
  <div className='flex flex-col w-full 
  items-center justify-center'>
  
  {/*factory row starts */}  
  <div className='flex flex-row-responsive items-center justify-center'>
  <div className='mr-6'> <br />
    {txhbb('Factory Design Pattern')} <br /> <br />

    Factory Pattern is a creational design pattern and   
    <BRR />  it is used to decouple  object's creation logic from their usage. 
     <BRR /> This is required when we may have to create similar objects   
    <BRR /> which follow the same interface or are subclasses of same superclass. <br /> <br />
    Some example are :  <br /> 
      - Creating different pieces in chess e.g. pawn, king, queen etc. <br />
      - Creating different type of cars in a car race video game. <br />  
      
  </div>
 { <div className=' flex flex-col mt-4 md:ml-6'>
  <YoutubeVideoDisplay videoId="jstNvc3d69s" />
 {/* <YoutubeVideoDisplay videoId="fLiMD-leeag" />
   
  <YoutubeVideoDisplay videoId="B8wmPFBKOgk" />
 */} 
 </div>
}
  </div>  {/*factory row ends */}
<br />
  {/* Also need a row for factory pattern vs singleton pattern comparison videos */}

  {/*singleton row starts */}  
  <div className='flex flex-row-responsive items-center justify-center'>

  <div className='mr-6'>
    {txhbb('Singleton Design Pattern')} <br /> <br />

    Singleton Pattern is used when we need a 
    <BRR />  single instance of an object.  <BRR /> 
    e.g. A connection pool manager for managing  <BRR />  database connection will be a singleton.  <br />
    Generally factories are implemented as a singleton <br />    
  </div>
  { // <YoutubeVideoDisplay videoId="htTtnBcAJxE" />
    <div className=' flex flex-col mt-4 md:ml-6'>
  <YoutubeVideoDisplay videoId="MCvV_MLJ0Z0" />
   
  
  
 </div>
}
  
 </div>
   {/*singleton row ends */}
  <br />
  {/* practice problem row starts */}  
  <div className='flex flex-row-responsive items-center justify-center'>
  <div className='md:mx-12'>{txbb("Practice Problem")}  <br />
  {txb("Design Chess Game")} <br />
  <LinkUI url="https://codezym.com/question/8" />  <br /><br />
  In Low Level Design of chess we use following design patterns <br />
   1. Chess Piece Factory to create different   <BRR /> 
   chess piece objects like king, queen, pawn etc <br />  <br /> 
   2. Strategy pattern to implement different moves   <BRR /> 
   e.g. straight move, diagonal move etc.  <br /> <br /> 
    3. Singleton pattern to ensure there is a  <BRR /> 
    single instance of chess piece factory object.  <br /> 
    Actually this question also serves as a {txbb('counter example')} 
      <BRR />  as to where we should not use singleton pattern.  <br />  <br /> 
    Even though we need only once instance of Chess Piece Factory  <BRR />  
    but it is being used from only one place as you will see in solution videos.   <BRR />  
    So making ChessPieceFactory a singleton is unnecessary in this case. 

   <br /> <br />
  </div>
  <div className='flex flex-col'>
  <br />  <BRR /> 
    <YoutubeVideoDisplay videoId='VWUuQWxmXYQ' />
    <BRR /> 
    <YoutubeVideoDisplay videoId='6HYvoBv78VU' />
    </div>
  </div>  {/* practice problem row ends */}

  </div>
  );
}

function DayFlyweightPattern(){
  return (
  <div className='flex flex-col w-full 
  items-center justify-center'>
  
  {/*factory row starts */}  
  <div className='flex flex-row-responsive items-center justify-center'>
  <div className='mr-6'> <br />
    {txhbb('Flyweight Design Pattern')} <br /> <br />

    Flyweight design pattern is used when we need to
    <BRR />    create a large number of 
    similar objects   <BRR /> It helps us save memory space 
    by sharing   <BRR /> constant state among objects.
     <br /> <br />
     <SPACE /> Some examples are <br />
      - Sharing 3-d images of thousands of soldiers <BRR />
      <SPACE /> in a computer war game
       <br />
      - Sharing styles of characters in a 
      text editor <BRR />
      <SPACE /> like Microsoft word.
       <br />  
      
  </div>
  {/** added items-center for vertical alignment */}
  <div className=' flex flex-col items-center mt-4 md:ml-6'>
  <YoutubeVideoDisplay videoId="ca82h-4c72g" 
  />
  {/* <YoutubeVideoDisplay videoId="uAcwcecAkDA" /> */}
  
 </div>
  </div>  {/*flyweight row ends */}
<br />
  {/* Also need a row for flyweight pattern vs factory pattern vs singleton pattern comparison videos */}

  {/* practice problem row starts */}  
  <div className='flex flex-row-responsive items-center justify-center'>
  <div className='md:mx-12 '>{txbb("Practice Problem")}  <br />
  {txb("Design a Text Editor/Word Processor like Microsoft Word")} <br />
  <LinkUI url="https://codezym.com/question/9" />  <br /><br />
  In a text editor, each character can have a different style  <BRR /> 
   in terms of parameter like colour, font size etc <br />
    <br />
   Also there can be millions of characters in a text editor file,  
   <BRR /> 
   but total number of different styles used will be just from  
   <BRR /> a few 100's to a few 1000's <br />  <br />
   So it makes sense to share character style as constant intrinsic state 
   <BRR /> and reduce memory footprint of program <br />
  </div>
  <div className='flex flex-col justify-center items-center md:mx-12'>
  <div><YoutubeVideoDisplay videoId='QG0OuN-57ks' /></div>
  <br />
  <div><YoutubeVideoDisplay videoId='hmQw5DfiRws' /></div>
  </div>
  </div>  {/* practice problem row ends */}

  </div>
  );
}

function DayObserverPattern(){
  return (
  <div className='flex flex-col w-full 
  items-center justify-center'>
  
  {/*factory row starts */}  
  <div className='flex flex-row-responsive items-center justify-center'>
  <div className='md:mr-6'> <br />
    {txhbb('Observer Design Pattern')} <br /> <br />

    Observer Pattern is a behavioural design pattern and  <BRR /> 
    it is used when you want to have loose coupling  <BRR />  between data which is getting changed and  <BRR />  multiple views or observers which need to receive the changes. <br /> <br /> 
    {txb('example:')} Giving 1 to 5 star rating to a product on ecommerce website  <BRR /> needs to be observed by the class which shows list of top rated products.
    <br /> <br />
    Observer pattern is generally used along with {txb('strategy pattern')}
    <BRR />  to inform different strategy classes about  <BRR />   changes occurring in the common data structure. <br /> <br /> 
     {txb('example:')} a customer support system may have different strategy classes
     <BRR />    to assign an agent to a customer support call. <BRR /> 
    Also each  strategy need to be informed whenever  <BRR />   an agent is assigned a case so that they can decide  <BRR />  whether to assign new cases to this agent or not. <br /> 
    Observer pattern will be used in this case.
    
    <br />  <BRR /> 
  </div>
  <div className='flex flex-col mt-4 md:ml-6'>
  <YoutubeVideoDisplay videoId="jEfs2LpmFuo" />
 {/* <YoutubeVideoDisplay videoId="RFz6SCx0KF8" />
  <br /> <br />
  <YoutubeVideoDisplay videoId="wsJ--Ln6QsI" />
   */}
 </div>

  </div>  {/*factory row ends */}
<br />
  {/* video for strategy and observer design pattern used together */}

  {/* practice problem row starts */}  
  <div className='flex flex-row-responsive items-center justify-center'>
  <div className='md:mx-12'>{txbb("Practice Problem")}  <br />
  {txb("Design a food ordering and rating system like ")} <BRR />
  <SPACE /> {txb("Zomato, Swiggy,  DoorDash")} <br />
  <LinkUI url="https://codezym.com/question/5" />  <br /><br />
  This system will have functionality to order food, rate orders  <BRR /> 
  and list top restaurants based on their rating <br /><br />
  Now whenever an order is rated then it has to observed  <BRR /> 
  by classes which return top restaurants lists based on their rating.  <BRR /> 
  So this is an ideal use case for {txb('observer design pattern')} 
  <br /> <br /> 
    Watch {txbb("Java")} and {txbb("Python")} video solutions for explanation.
   <br /> <br />
  </div>
  <div className='flex flex-col'>
    <YoutubeVideoDisplay videoId='v9ehOtY_x7Q' />
  <SPACE2 />
  <YoutubeVideoDisplay videoId='KGN-pSlMZgg' />
  </div>
  </div>  {/* practice problem row ends */}

  </div>
  );
}

function DayStatePattern(){
  return (
  <div className='flex flex-col w-full 
  items-center justify-center'>
  {/* later add question and videos on atm machine and vending machine */}
  {/*state row starts */}  
  <div className='flex flex-row-responsive items-center justify-center'>
  <div className='md:mr-6'> <br />
    {txhbb('State Design Pattern')} <br /> <br />

    State is a behavioural design pattern and it is used when 
     <BRR /> an object has to change its behaviour as its internal state changes. 
     <br />  <br /> 
    {txb('For example')} an ATM machine may be in different states  
    <BRR />  like idle, money withdrawal in progress, validating pin etc  
    <BRR />  and will respond to keyboard button presses differently in each state.   <BRR />  
    The same keyboard can be used to input user's PIN as well as   <BRR />   mobile number,
    depending on the current state of ATM. 
    <br /> <br /> 
    {txb('another example')} is of a Cab booking System.  <BRR /> 
    Ride can be in states like: <BRR /> Ride Requested, Ongoing, Finished, Cancelled  <BRR /> 
      which will have multiple states and in each of these states  <BRR />  
       calculation for a common behaviour like  <BRR />  
      {txb(' timeTakenToReachDestaination()')} will be different.
   
    <br />  <BRR /> 
  </div>
  <div className='flex flex-col mt-4 md:ml-6'>
  <YoutubeVideoDisplay videoId="HRnq_aeMraM" />
  {/*<YoutubeVideoDisplay videoId="PsDVcvAhU5s" 
  />  <br />
  <YoutubeVideoDisplay videoId="H6xLTb_ktbU" 
  />
  */}
 </div>
  </div>  {/*state row ends */}

<br />
 
  {/* practice problem row starts */}  
  <div className='flex flex-row-responsive items-center justify-center'>
  <div className='md:mx-12'>{txbb("Practice Problem")}  <br />
  {txb("Design an elevator management System with multiple lifts")} <br />
  <LinkUI url="https://codezym.com/question/11" />  <br /><br />
  A lift in an elevator system can be in one of three states.   <BRR /> 
  {txb('Moving Up,')} {txb('Moving Down')} and {txb('Idle')}    <BRR /> 
  And in each state it will behave differently in taking decisions
   like   <BRR /> 
  whether to stop on a floor, add a new request or not etc  <br /> <br />
  So we will use {txb('state design pattern')} to solve this problem.
   <br />  <BRR /> 
  </div>
  <div className='flex flex-col items-center justify-center'>
  <YoutubeVideoDisplay videoId='zieb_0WujWY' />
  <BRR /> 
    <YoutubeVideoDisplay videoId='dCjWFcE1mZ4' />
    {/* <YoutubeVideoDisplay videoId='_2Vpjniu5fM' /> */}
    </div>
  </div>  {/* practice problem row ends */}

  </div>
  );
}

export {FirstPreparationPlan}