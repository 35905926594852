import React from 'react';
import LoginModal from '../login/email_login_modal';
import { getUIState } from '../login/auth_utils';
import { PremiumOneLineComponent } from '../premium/premium_discounts';

function NavbarTop() {
  const showContact = false
  const uiState=getUIState()
  console.log(" ui state "+uiState)
  return (
    <div className="flex flex-col">

    <nav className="fixed top-0 left-0 z-50 w-full bg-white text-gray-800 py-1 px-6 border-b border-gray-200">
    {/*  <div className="flex items-center justify-between">
        <a href="#" className="text-xl font-bold">Your Website</a>
        <button className="bg-gray-800 hover:bg-gray-700 text-white px-3 py-2 rounded-md">Menu</button>
      </div>
  */}
    <div className=" flex items-center justify-between mt-2">
      {/* buttons which are shown only on desktop/laptop */}
      <div className="hidden md:flex md:space-x-4"> 
        <a href="/" className="text-2xl px-4 py-2 font-bold mr-6  hover:bg-gray-100 hover:text-black">CodeZym</a>
        
      {  <a href="/premium" className="mr-6 px-4 py-2
        text-lg font-sans rounded-full
          text-orange-500 bg-orange-100 
          hover:bg-orange-300 hover:text-black 
          ">Premium</a>
      }

      { /*
       <a href="/wholesale" className="mr-6 px-4 py-2 
      text-gray-800 font-sans 
         hover:bg-gray-100 hover:text-black">
          Bulk Subscription</a>
      */}

      {
        <a href="https://topmate.io/prashant_priyadarshi/665067"
          className="mr-6 px-4 py-2 hover:bg-gray-100 hover:text-black"
          target='_blank'>Mock Interview</a>
    }
      { 
      /*
        <a href="#" className="mr-6 px-4 py-2 hover:bg-gray-600 ">Premium</a>
      */
      }
      
        <a href="/about" className="mr-6 px-4 py-2 hover:bg-gray-100  hover:text-black">Why CodeZym ?</a>
      
      { showContact &&   <a href="#" className="mr-6 px-4 py-2 hover:bg-gray-100 hover:text-black">Contact</a>
      }
      </div>
       
        {/* Mobile Menu Button (Shown on Mobile) */}
        <div className="md:hidden">
        <a href="/" className="text-xl  mr-4  font-bold   hover:bg-gray-100 hover:text-black">CodeZym</a>
          </div>

          {/* Mobile Menu Buttons (Hidden on Medium and Larger Screens) */}
          <div className="flex align-middle text-center md:hidden text-gray-900">
          

       {/*
          <a href="https://topmate.io/prashant_priyadarshi/665067"
           className=" text-sm px-1  hover:bg-gray-100 hover:text-black"
           target='_blank'>Mock Interview</a>
      */}
        { /*<a href="/" className="px-2 hover:bg-gray-100 hover:text-black ">Home</a>
          <a href="#" className="mr-6 px-4 py-2 hover:bg-gray-600 ">Premium</a>
        */
        }
        
        {/*  <a href="/about" className="text-sm px-1 hover:bg-gray-100  hover:text-black">Why CodeZym ?</a>
        */}
       { showContact &&   <a href="#" className="text-sm  hover:bg-gray-100 hover:text-black">Contact</a>
        }
          </div>

        <div>
        <LoginModal />
      {/*  
          
      */}
         {/* <button className="bg-gray-900 hover:bg-gray-600 text-white px-3 py-2 rounded-md">Login/SignUp</button>
         */}
        </div>
      </div>
    </nav>

         
    </div>
  );
}

function NavbarWithOnlyHome() {

  return (
    <nav className="fixed top-0 left-0 z-50 w-full bg-white text-gray-800 py-1 px-6 border-b border-gray-200">
    
      <div className=" flex items-center justify-between mt-2">
        <div className="hidden md:flex md:space-x-4"> 
          <a href="/" className="text-3xl px-4 py-2 font-bold mr-6  hover:bg-gray-100 hover:text-black">CodeZym</a>
        
         {/* 
         <a href="/" className="mr-6 px-4 py-2 hover:bg-gray-100  hover:text-black">Home</a>
      */}

      
        </div>
      </div>
    </nav>
  );
}

export  {NavbarTop, NavbarWithOnlyHome};
